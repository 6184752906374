import * as React from "react"
import * as styles from "../styles/home.module.scss"
import Template from "../components/ui/Template"
import { Button } from "@material-ui/core"
import { Helmet } from "react-helmet"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return <Template currentPage="our-story" lightnessTheme="dark">
      <Helmet>
        <title>Daya Selaras Group - Our Story</title>
      </Helmet>
      <div className={styles.jumbotron} style={{backgroundImage: "url('/static/images/our-story.jpg')"}}>
        <div className={styles.fence}>
            <div className={styles.ptdckSlide}>
                <div className={styles.title}>
                OUR VISION
                </div>
                <div className={styles.info}>
                  To become the leading sustainable industrial hub that links people within the industrial loop starting from waste paper to packaging.
                </div>
            </div>
        </div>
      </div>
      <div className={styles.halfPage} style={{backgroundColor: "#d9d9d9"}}>
        <div className={styles.fence}>
            <div className={styles.mission}>
                <div className={styles.title}><span>MISSION &</span> <span>VALUES</span></div>
                <div className={styles.missionItem}>
                    <div className={styles.logo} style={{backgroundImage: "url('/static/images/mission/1.png')"}}></div>
                    <div className={styles.name}>PROGRESS</div>
                    <div className={styles.description}>We believe in continuous-improvement culture that is necessary to meet our customer needs.</div>
                </div>
                <div className={styles.missionItem}>
                    <div className={styles.logo} style={{backgroundImage: "url('/static/images/mission/2.png')"}}></div>
                    <div className={styles.name}>ADAPTABILITY</div>
                    <div className={styles.description}>To be agile to meet the ever-changing market needs.</div>
                </div>
                <div className={styles.missionItem}>
                    <div className={styles.logo} style={{backgroundImage: "url('/static/images/mission/3.png')"}}></div>
                    <div className={styles.name}>ACCOUNTABILITY</div>
                    <div className={styles.description}>To be responsible to all stakeholders.</div>
                </div>
                <div className={styles.missionItem}>
                    <div className={styles.logo} style={{backgroundImage: "url('/static/images/mission/4.png')"}}></div>
                    <div className={styles.name}>TEAMWORK</div>
                    <div className={styles.description}>Together we win is our company motto.</div>
                </div>
                <div className={styles.missionItem}>
                    <div className={styles.logo} style={{backgroundImage: "url('/static/images/mission/5.png')"}}></div>
                    <div className={styles.name}>SUSTAINABILITY</div>
                    <div className={styles.description}>We advocate for zero-waste and circular economy of waste paper.</div>
                </div>
            </div>
        </div>
        <div className={styles.halfPage} style={{backgroundColor: "#c7d6cd"}}>
            <div className={styles.fence}>
                <div className={`${styles.mission} ${styles.missionWithResponsiveImage}`}>
                    <div className={styles.title}><span>OUR</span> <span>MILESTONE</span></div>
                    <div className={styles.responsiveImage} style={{backgroundImage: "url('/static/images/history.png')"}}></div>
                </div>
            </div>
        </div>
      </div>
    </Template>
  }
}

export default IndexPage
